<template>
  <!--保司管理-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="名称/编码"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="reset">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">

              </div>
            </div>
            <div>

            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :loading="loading"
            >
              <template v-slot:operationSlot="{ scope }">
<!--                <el-link-->
<!--                  :underline="false"-->
<!--                  type="warning"-->
<!--                  @click="openCategory(scope.row)"-->
<!--                  style="margin-right: 10px"-->
<!--                >编辑</el-link-->
<!--                >-->
                <el-link
                  :underline="false"
                  type="warning"
                  @click="openformal(scope.row)"
                  style="margin-right: 10px"
                >正式</el-link
                >
                <el-link
                  :underline="false"
                  type="warning"
                  @click="opentest(scope.row)"
                  style="margin-right: 10px"
                >测试</el-link
                >
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

    </div>

    <!-- 引入编辑组件 -->
    <Edit v-if="showEdit === true" :data="current" :visible.sync="showEdit"/>
    <!--  引入正式组件  -->
    <formaltesting v-if="showformaltesting === true" :data="showformaltestingdata" :visible.sync="showformaltesting" @done="getList"/>
    <!--  引入测试组件  -->
    <Test v-if="showtest === true" :data="showtestdata" :visible.sync="showtest"/>

  </div>
</template>

<script>
// 权限
import {ShowTable} from '@/utils'
// 引入编辑组件
import Edit from './components/edit.vue'
//引入正式组件
import formaltesting from './components/Formaltesting.vue'
//引入测试组件
import Test from './components/test.vue'

// 引入的接口
import {
  get_insurerlist,
} from '@/api/system'

export default {
  components:{
    Edit,
    formaltesting,
    Test
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        // {
        //   label: "",
        //   // type: "index",
        //   // width: "70"
        //   prop: "",
        //   isShow: true
        // },
        {
          label: "保险公司名称",
          prop: "name",
          isShow: true
        },
        {
          label: "保险公司简称",
          prop: "abbreviation",
          isShow: true
        },
        {
          label: "所在省份",
          prop: "province",
          isShow: true
        },
        {
          label: "所在城市",
          prop: "city",
          isShow: true
        },
        {
          label: "所在区域",
          prop: "district",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      //编辑
      showEdit:false,
      current:null,

      //正式
      showformaltesting:false,
      showformaltestingdata:null,

      //测试
      showtest:false,
      showtestdata:null
    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
  },

  methods:{
    /* 刷新表格 */
    reload() {
      this.$refs.myTable.reload();
    },
    /* 重置搜索 */
    reset() {
      this.reload();
    },

    // 获取列表
    getList(){
      get_insurerlist().then(async (res) => {
        this.List = res.data;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },

    //编辑保司
    openCategory(row){
      console.log(row);
      this.current = row;
      this.showEdit = true;
    },
    //打开正式
    openformal(row){
      console.log(row);
      this.showformaltestingdata = row;
      this.showformaltesting = true;
    },
    //打开测试
    opentest(row){
      console.log(row);
      this.showtestdata = row;
      this.showtest = true;
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
